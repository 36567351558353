// extracted by mini-css-extract-plugin
export var cardAction = "carousel-slide-module--cardAction--7H-pr";
export var cardActions = "carousel-slide-module--cardActions--D+06L";
export var cardHidden = "carousel-slide-module--cardHidden--QF9CF";
export var cardHideSlow = "carousel-slide-module--cardHideSlow--bZV5C";
export var cardHideSlowActive = "carousel-slide-module--cardHideSlowActive--wJYj5";
export var cardMediaFrame = "carousel-slide-module--cardMediaFrame--j1hcL";
export var cardMediaLandscape = "carousel-slide-module--cardMediaLandscape--8Xho8";
export var cardMediaPortrait = "carousel-slide-module--cardMediaPortrait--xt5e0";
export var cardShow = "carousel-slide-module--cardShow--Rr63s";
export var cardShowDone = "carousel-slide-module--cardShowDone--wXI8-";
export var cardShowFastActive = "carousel-slide-module--cardShowFastActive--rJujc";
export var cardShowSlowActive = "carousel-slide-module--cardShowSlowActive--o2YpI";
export var cardSubTitle = "carousel-slide-module--cardSubTitle--2i3zN";
export var cardTitle = "carousel-slide-module--cardTitle--XtdFV";
export var slot = "carousel-slide-module--slot--brcg8";